import { API_URL } from './settings'

const getAllAvailableConnectors = () =>
  fetch(
    `${API_URL}/api/v1/capacityProvider?state=true`,
    {
      credentials: 'include'
    }
  ).
  then(res => res.json()).
  then((data: Response) => data).
  catch(() => [])

export default getAllAvailableConnectors

import { API_URL } from './settings'

const saveAccount = async (body : AccountInformation) => {
  return fetch(
    `${API_URL}/api/v1/account/update`,
      {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }
    ).
    then(() => ({ body }))
}

export default saveAccount

import { API_URL } from './settings'

interface Params {
  account: string
  scacCode: string
}

const getContactEmail = ({ account, scacCode }: Params) =>
  fetch(
    API_URL + '/api/v1/account/getContactEmailByAccountAndScacCode?account=' +
      account + '&scacCode=' + scacCode,
    {
      credentials: 'include'
    }
  ).then(res => res.json());

export default getContactEmail;

import React  from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { TableCell, TableRow, Button, Tooltip, Snackbar } from '@material-ui/core'

import useQuote from '../hooks/useQuotes'
import { Check } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'

interface Props {
  quote: Quote
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      border: '1px solid #CDCDCD',
      boxShadow: '0 0 black',
      backgroundColor: theme.palette.background.paper,
      margin: '0 0',
      padding: theme.spacing(2),
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '120px',
      maxHeight: '32px',
    },
    button: {
      maxWidth: '120px',
      width: '500px',
      padding: theme.spacing(1),
    },
    center: {
      flexDirection: 'column',
      margin: '0 auto'
    },
    imageContainer:{
      margin: '12px auto',
      height: '35px',
      width: '120px',
    },
  })
)

const AdditionalCarrier = ({ quote }: Props) => {
  const classes = useStyles()
  const { handleConnect, isConnected, isFetchError, setIsFetchError } = useQuote()
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsFetchError(false);
  };

  return (
    <>
    <TableRow>
      <TableCell scope="row">
        <div className={classes.imageContainer}>
          <img className={classes.img} src={quote.logo} alt={quote.scac_code} />
        </div>
      </TableCell>
      <TableCell align="center">
          {quote.responseTime}
      </TableCell>
      <TableCell align="center">
        {quote.ebol ?
            <>
              <Check style={{ marginRight: '0.25rem', fontSize: 16, color: '#00854A' }} />
            </> :
            ''
          }
      </TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell align="right">
        {isConnected ?
          <Tooltip arrow placement="left" title="Request sent. Typical approval time to view spot quotes takes 1-3 business days">
            <span style={{color: '#92278F', display: 'inline-block', width: '120px', fontWeight: 600, textAlign: 'center'}}>Requested</span>
          </Tooltip>
          :
          <Button
          onClick={() => handleConnect(quote.scac_code)}
          className={classes.button}
          variant="outlined"
          color="primary"
        >
          Connect
        </Button>
        }
        {
          <Snackbar 
            open={isFetchError}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Alert onClose={handleClose} severity="warning">
              An error has occurred please contact support
            </Alert>
          </Snackbar> 
        }
      </TableCell>
    </TableRow>
    </>
  )
}

export default AdditionalCarrier

import React from 'react'

import Quote from './Quote'

interface Props {
  quotes: Quote[]
  ratingParams: SearchParams | null
}

const QuoteList = ({ quotes, ratingParams }: Props) => {
  const quoteList = quotes

  return (
    <>
      {
        Boolean(quotes.length) && quoteList.map(quote => <Quote
          key={quote.response.scacCode + Math.random()}
          quote={quote}
          ratingParams={ratingParams}
        />)
      }
    </>
  )
}

export default QuoteList

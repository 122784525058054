import React from 'react'
import {Button, Container, Grid, Typography} from '@material-ui/core'
import {Link} from '@reach/router'

import useRecentSearches from '../hooks/useRecentSearches'
import RecentQuoteList from '../components/RecentQuoteList'
import RecentBookingList from '../components/RecentBookingList'
import NavBar from "../components/NavBar";

const Dashboard = () => {
    const {isLoading, quotes, bookings} = useRecentSearches();

    return (
        <>
            <header><NavBar></NavBar></header>
            <Container>
                <Grid style={{marginTop: '2rem', marginBottom: '2rem'}} container alignItems="center" spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h4">Truckload Rating</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} container justify="flex-end">
                        <Link style={{textDecoration: 'none'}} to="/">
                            <Button variant="contained" size="large" color="primary" style={{padding: '1rem 2rem'}}>
                                Create Shipment
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
                <RecentQuoteList isLoading={isLoading} quotes={quotes}/>
                <RecentBookingList isLoading={isLoading} bookings={bookings}/>
            </Container>
        </>
    )
}

export default Dashboard

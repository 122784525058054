import React, { useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { MenuItem, FormControl, ListItemText, Select, Icon, OutlinedInput, Checkbox } from '@material-ui/core'
import { useQuery } from 'react-query'

import getAccesorials from '../services/getAccesorials'

import ForkliftIcon from '../assets/forklift.svg'

const DAY_IN_SECONDS = 24 * 60 * 60

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
  }),
)

interface Props {
  initialAccesorials: Accesorials[]
  handleChange: (accessorials: Accesorials[]) => void
}

const AccesorialsInput = ({ initialAccesorials, handleChange }: Props) => {
  const classes = useStyles()
  const { data } = useQuery('accessorials', getAccesorials, {
    staleTime: DAY_IN_SECONDS * 1000,
  })
  const [accessorials, setAccesorials] = useState<string[]>(initialAccesorials.map(({ name }) => name))
  const availableAccesorials = data || []

  const handleAccesorialsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedAccesorialsNames = event.target.value as string[]
    const selectedAccesorials = availableAccesorials.filter(({ name }) => selectedAccesorialsNames.includes(name))
    setAccesorials(selectedAccesorialsNames)
    handleChange(selectedAccesorials)
  }

  return (
    <FormControl fullWidth className={classes.formControl}>
      <Select
        id="demo-mutiple-checkbox"
        multiple
        name="accessorials"
        value={accessorials}
        onChange={handleAccesorialsChange}
        input={<OutlinedInput id="select-multiple-accessorials-2" />}
        renderValue={(selected) => (selected as string[]).join(', ')}
        startAdornment={<Icon style={{ marginRight: '0.5rem' }}><img src={ForkliftIcon} alt="Forklift Icon" /></Icon>}
      >
        {
          [...availableAccesorials].map((item: Accesorials) => (
            <MenuItem key={item._id} value={item.name}>
              <Checkbox checked={accessorials.indexOf(item.name) > -1} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}

export default AccesorialsInput

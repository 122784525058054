import React, { useState } from 'react'
import { Button, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core'

import RecentQuote from './RecentQuote'
import ProgressBar from './ProgressBar'
import { limitResults } from '../helpers'

interface Props {
  quotes: RecentSearch[]
  isLoading: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: '540px',
      margin: '1rem auto',
      [theme.breakpoints.down('sm')]: {
        width: '240px',
      },
    },
    my: {
      marginTop: '2rem',
      marginBottom: '2rem',
    }
  })
)

const RecentQuoteList = ({ isLoading, quotes }: Props) => {
  const classes = useStyles()
  const [limitQuotes, setLimitQuotes] = useState(true)
  const [recentQuotes, showButton] = limitResults(quotes, limitQuotes)

  return (
    <Grid className={classes.my} container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">Recent Quotes</Typography>
      </Grid>
      {
        isLoading
          ? <ProgressBar />
          : recentQuotes.map(quote => <RecentQuote key={quote._id} quote={quote} />)
      }
      {
        showButton && <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={() => setLimitQuotes(!limitQuotes)}
        >
          {limitQuotes ? 'Show All Quotes' : 'Hide Quotes'}
        </Button>
      }
    </Grid>
  )
}

export default RecentQuoteList

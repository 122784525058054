import { useState, useEffect } from 'react'

import getLocations from '../services/getLocations'

export default function LocationInput(input: string) {
  const [loading, setLoading] = useState(false)
  const [locations, setLocations] = useState<Location[]>([])

  useEffect(() => {
    if (input) {
      setLoading(true)
      getLocations(input).then(locations => {
        setLocations(locations)
        setLoading(false)
      })
    }
  }, [input])

  return { loading, locations }
}

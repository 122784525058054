import { API_URL } from './settings'

const bookShipment = async (body: BookingData) => {
  return fetch(
    `${API_URL}/api/v1/tender/dispatch`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
  )
};

export default bookShipment

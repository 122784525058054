import { API_URL } from './settings'

interface ConnectCarrier {
  scac_code: string
}

const postConnectCarrier = async (body: ConnectCarrier) => {
  return fetch(
    `${API_URL}/api/v1/tender/requestAccess`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
  )
}

export default postConnectCarrier

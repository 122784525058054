import defaultLogo from './assets/images/logo.png'

type Miles = string | null

export const formatDate = (date: string) => {
  const formatedDate = new Date(date)
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(formatedDate)
  const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(formatedDate)

  return `${month}, ${day}`
}

export const carrierLogo = (logo: string | undefined) => {
  try {
    const carrierLogo = logo && require(`./assets/images/${logo}.png`)
    return carrierLogo
  } catch (_error) {
    return defaultLogo
  }
}

export const limitResults = <T>(content: T[], limitContent: boolean): [T[], boolean] => {
  return content.length > 3
    ? [(limitContent ? [...content].slice(0, 5) : content), true]
    : [content, false]
}

export const limitResultsEmpty = <T>(content: T[], limitContent: boolean): [T[], boolean] => {
  return content.length >= 1
    ? [(limitContent ? [...content].slice(0, 0) : content), true]
    : [content, false]
}

export const equipmentTypeList = [
  { symbol: 'DV', name: 'Dry Van' },
  { symbol: 'RF', name: 'Reefer' },
  { symbol: 'FL', name: 'Flatbed' }
]

export const currentDate = new Date().toISOString().split('T')[0]

const getRad = (x: number) => (x * Math.PI) / 180

export const getMiles = (origin: Location, destination: Location) => {
  //function that calculates the distance between two points in miles

  if (!origin.lat || !destination.lat) return null

  // Earth radius in km
  const R = 6378.137
  const dLat = getRad(destination.lat - origin.lat)
  const dLong = getRad(destination.lng - origin.lng)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(getRad(origin.lat)) *
    Math.cos(getRad(destination.lat)) *
    Math.sin(dLong / 2) *
    Math.sin(dLong / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  // return kilometres
  const d = R * c

  // covenrt kilometres to miles
  return (d * 0.62137).toFixed(0)
}

const getEstimatedTransitDays = (miles: Miles) => miles ? Math.round(+miles / 550) : 0

export const setTransitDays = (transitDays: string, miles: Miles) => transitDays
  ? {
    days: +transitDays,
    condition: "Provided by Carrier."
  }
  : {
    days: getEstimatedTransitDays(miles),
    condition: "Transit days not provided by carrier. Estimated by Project44."
  }

export const estimatedDeliveryDate = (shipmentDetails: ShipmentDetails) => {
  const estimatedTransitDays = getEstimatedTransitDays(shipmentDetails.miles)
  const shipmentDate = new Date(shipmentDetails.shipDate)

  const deliveryDate = formatDate(
    new Date(shipmentDate.setDate(shipmentDate.getDate() + estimatedTransitDays)).toString()
  )

  return deliveryDate + ' (estimated)'
}

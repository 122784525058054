import React, {useEffect} from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Button, Snackbar, TableCell, TableRow, Typography } from '@material-ui/core'
import { Error } from '@material-ui/icons'

import useQuote from '../hooks/useQuotes'
import Alert from '@material-ui/lab/Alert/Alert'

interface Props {
  quote: Quote
  ratingParams: SearchParams | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      border: '1px solid #CDCDCD',
      boxShadow: '0 0 black',
      backgroundColor: theme.palette.background.paper,
      margin: '0.25rem 0',
      padding: theme.spacing(2, 4),
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '120px',
      maxHeight: '100%',
    },
    button: {
      maxWidth: '120px',
      width: '100%',
      margin: '2rem auto',
    },
    textWithIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    imageContainer:{
      margin: '12px auto',
      height: '35px',
      width: '120px',
    },
  })
)

const Quote = ({ quote, ratingParams }: Props) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false);
  const { handleContact,  isFetch} = useQuote()

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect (() =>{
    if(isFetch){
      setOpen(true)
    }
  }, [isFetch])
  
  return (
    <TableRow>
      <TableCell scope="row">
        <div className={classes.imageContainer}>
          <img className={classes.img} src={quote.response.logo} alt={quote.response.scacCode} />
        </div>
      </TableCell>
      <TableCell align="center" colSpan={4}>
        <div className={classes.textWithIcon}>
          <Error color="error" style={{ marginLeft: '1rem', marginRight: '0.5rem', fontSize: 16 }} />
          <Typography color="error" variant="body2">Unable to retrieve quote. Contact the carrier’s support team to resolve this issue.</Typography>
        </div>
      </TableCell>
      <TableCell align="right">
        <>
          <Button
            onClick={() => handleContact(quote.response.scacCode, ratingParams)}
            className={classes.button}
            variant="outlined"
            color="primary"
          >
            Contact
          </Button>
          {
            isFetch && 
            <Snackbar 
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Alert onClose={handleClose} severity="success">
                Your request has been successfully received
              </Alert>
            </Snackbar> 
          }
        </>
      </TableCell>
    </TableRow>
  )
}

export default Quote

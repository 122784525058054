import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Button, Grid, Paper, Typography } from '@material-ui/core'
import { ArrowForward, Refresh } from '@material-ui/icons'
import { navigate } from '@reach/router'

import { formatDate, currentDate } from '../helpers'
import useRatingForm from '../hooks/useRatingForm'

interface Props {
  quote: RecentSearch
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2, 4),
    },
    button: {
      width: '100%',
      margin: '0.5rem auto'
    },
  })
)

const RecentQuote = ({ quote: { request } }: Props) => {
  const classes = useStyles()
  const { handleAllParamsChange } = useRatingForm()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    navigate('/', { state: { isRequote: true } })
      .finally(() => handleAllParamsChange({
        ...request.freightInformation,
        ...request.shipmentDetails,
        ...request.freightInformationLTL,
        shipDate: new Date(request.shipmentDetails.shipDate) < new Date()
          ? currentDate
          : request.shipmentDetails.shipDate
      }))
  }

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper} square={true}>
        <Grid container>
          <Grid item xs={3} md={2}>
            <Typography variant="subtitle1">Last Quoted Date</Typography>
            <Typography variant="body2">{formatDate(request.shipmentDetails.shipDate)}</Typography>
          </Grid>
          <Grid item xs={3} md={2}>
            <Typography variant="subtitle1">{`${request.shipmentDetails.origin.CityName}, ${request.shipmentDetails.origin.StatCode}`}</Typography>
          </Grid>
          <Grid item xs={1} md={1}>
            <ArrowForward />
          </Grid>
          <Grid item xs={2} md={2}>
            <Typography variant="subtitle1">{`${request.shipmentDetails.destination.CityName}, ${request.shipmentDetails.destination.StatCode}`}</Typography>
          </Grid>
          <Grid item xs={3} md={2}>
            <Typography variant="subtitle1">Total Weight</Typography>
            <Typography variant="body2">{Intl.NumberFormat().format(+request.freightInformation.totalweight)}</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              startIcon={<Refresh />}
              onClick={handleClick}
            >
              Refresh Quote
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default RecentQuote

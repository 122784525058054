import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { TableCell, TableRow, Tooltip } from '@material-ui/core'

import { Check } from '@material-ui/icons'

interface Props {
  quote: Quote
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      border: '1px solid #CDCDCD',
      boxShadow: '0 0 black',
      backgroundColor: theme.palette.background.paper,
      margin: '0.25rem 0',
      padding: theme.spacing(1, 4),
      minHeight: '140px',
      maxHeight: '350px'
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '120px',
      maxHeight: '32px',
    },
    center: {
      flexDirection: 'column',
      margin: '0auto'
    },
    textWithIcon: {
      display: "flex",
      alignItems: "center",
    },
    imageContainer:{
      margin: '12px auto',
      height: '35px',
      width: '120px',
    },
    btnRequested:{
      color: '#92278F',
      display: 'inline-block',
      fontWeight: 600,
      width: '120px',
      textAlign: 'center',
    }

  })
)

const PedingQuote = ({ quote }: Props) => {
  const classes = useStyles()
  return (
    <>
      <TableRow>
        <TableCell scope="row">
          <div className={classes.imageContainer}>
            <img className={classes.img} src={quote.logo} alt={quote.scac_code} />
          </div>
        </TableCell>
        <TableCell align="center" scope="row">
            {quote.responseTime}
        </TableCell>
        <TableCell align="center" scope="row">
          {quote.ebol ?
            <>
              <Check style={{ marginRight: '0.25rem', fontSize: 16, color: '#00854A' }} />
            </> :
            ''
          }
        </TableCell>
        <TableCell align="center">

        </TableCell>
        <TableCell align="center">

        </TableCell>
        <TableCell align="right">
          <Tooltip arrow placement="left" title="Request sent. Typical approval time to view spot quotes takes 1-3 business days">
            <span className={classes.btnRequested}>Requested</span>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  )
}

export default PedingQuote

import { useRatingParams } from '../context/RatingParams'

const useRatingForm = () => {
  const { params, setParams } = useRatingParams()

  const handleParamsChange = (evt: React.ChangeEvent<{ name?: string, value: unknown }>) => {
    const { name, value } = evt.target
    if (name) {
      setParams(prevParams => {
        return {
          ...prevParams,
          [name]: value
        }
      })
    }
  }

  const handleLocationChange = (point: Point) => (location: Location) => {
    setParams(prevParams => ({ ...prevParams, [point]: location }))
  }

  const handleAccesorialsChange = (accessorials: Accesorials[]) => {
    setParams(prevParams => ({ ...prevParams, accessorials }))
  }

  const handleAllParamsChange = (params: RatingParams) => setParams(params)

  return {
    params,
    handleParamsChange,
    handleAllParamsChange,
    handleLocationChange,
    handleAccesorialsChange
  }
}

export default useRatingForm
import { API_URL } from './settings'

export const getQuotes = async ({ connectorId, ratingParams }: QuoteRequestData) => {
  const { searchId, body } = ratingParams
  const res = await fetch(
    `${API_URL}/api/v1/tender/getRateQuotes?connectorId=${connectorId}&mode=${body.shipmentDetails.mode}&&searchId=${searchId}`,
    {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )

  return res.json()
}

import React, { useContext } from 'react';
import { UserContext } from '../context/User';
import { navigate } from "@reach/router";

type state = {
  [key: string]: Function
};

const checkAuthState: state = {
  auth: (user: any) => {
    if (user.toc) {
      navigate('/rating');
    } else {
      navigate('/landing');
    }
  },
  loading: () => { },
  error: () => { }
};

const Home = () => {
  const { user: { auth, user: { user } } } = useContext(UserContext);
  checkAuthState[auth](user);
  return (<></>);
};

export default Home;

import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Accordion, Grid, AccordionSummary, AccordionDetails, Typography, Button, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ArrowForward, Refresh } from '@material-ui/icons'
import { navigate } from '@reach/router'

import { formatDate, carrierLogo, equipmentTypeList, currentDate, estimatedDeliveryDate } from '../helpers'
import useRatingForm from '../hooks/useRatingForm'

interface Props {
  quote: RecentSearch
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      border: '1px solid #CDCDCD',
      boxShadow: '0 0 black',
      padding: theme.spacing(1),
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    button: {
      width: '100%',
      margin: '0.5rem auto'
    },
  })
)

const RecentBooking = ({ quote: { request, quote } }: Props) => {
  const classes = useStyles()
  const { handleAllParamsChange } = useRatingForm()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    navigate('/', { state: { isRequote: true } })
      .finally(() => handleAllParamsChange({
        ...request.freightInformation,
        ...request.shipmentDetails,
        ...request.freightInformationLTL,
        shipDate: new Date(request.shipmentDetails.shipDate) < new Date()
          ? currentDate
          : request.shipmentDetails.shipDate
      }))
  }

  return (
    <Grid item xs={12}>
      <Accordion className={classes.paper} square={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12} md={2}>
              <img className={classes.img} src={carrierLogo(quote?.response.scacCode)} alt={quote?.response.scacCode} />
            </Grid>
            <Grid item xs={4} md={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">{`${request.shipmentDetails.origin.CityName}, ${request.shipmentDetails.origin.StatCode}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">{formatDate(request.shipmentDetails.shipDate)}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={2} md={1}>
              <ArrowForward />
            </Grid>
            <Grid item xs={4} md={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">{`${request.shipmentDetails.destination.CityName}, ${request.shipmentDetails.destination.StatCode}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {
                    quote?.response.deliveryDateTime
                      ? formatDate(quote?.response.deliveryDateTime)
                      : estimatedDeliveryDate(request.shipmentDetails)
                  }
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={2}>
              <Typography variant="h5">${Intl.NumberFormat().format(quote ? +quote.response.totalCharge.value : 0)}</Typography>
            </Grid>
            <Grid item container xs={12} md={3}>
              <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                startIcon={<Refresh />}
                onClick={handleClick}
              >
                Refresh Quote
              </Button>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              <Divider style={{ marginBottom: '1rem' }} />
            </Grid>
            <Grid item xs={4} md={2}>
              <Typography variant="subtitle1">Quote ID</Typography>
              <Typography variant="body2">#{quote?.response.quoteId}</Typography>
            </Grid>
            <Grid item xs={4} md={3}>
              <Typography variant="subtitle1">Equipment Type</Typography>
              <Typography variant="body2">
                {
                  equipmentTypeList.find(({ symbol }) => symbol === request.freightInformation.equipmentType)?.name
                }
              </Typography>
            </Grid>
            <Grid item xs={4} md={2}>
              <Typography variant="subtitle1">Total Weight</Typography>
              <Typography variant="body2">{Intl.NumberFormat().format(+request.freightInformation.totalweight)}</Typography>
            </Grid>
            <Grid item xs={4} md={2}>
              <Typography variant="subtitle1">Number of Skids</Typography>
              <Typography variant="body2">{request.freightInformation.numberSkids}</Typography>
            </Grid>
            <Grid item xs={4} md={3}>
              <Typography variant="subtitle1">Linear Feet</Typography>
              <Typography variant="body2">{request.freightInformation.linearFeet}</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

export default RecentBooking

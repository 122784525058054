import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Link } from "@reach/router"
import {
  AccountCircle,
  Mail,
  Notifications,
  MoreVert
} from '@material-ui/icons'
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  MenuItem,
  Menu
} from '@material-ui/core'

import Logo from '../assets/images/logo.svg'
import AccountModal from "./AccountModal";

type LinkProps = { isCurrent: boolean }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    toolbar: {
      height: '66px'
    },
    img: {
      margin: 'auto 1.2rem',
      display: 'block',
      width: '140px',
    },
    link: {
      color: theme.palette.secondary.main,
      fontWeight: 600,
      height: '100%',
      display: 'flex',
      padding: '1rem 0',
      margin: '0 1rem',
      textDecoration: 'none',
      boxSizing: 'border-box',
      alignItems: 'center',
      borderBottom: `5px solid transparent`,
      transition: 'all .3s ease',
      '&:hover': {
        color: theme.palette.primary.main,
        borderBottom: `5px solid ${theme.palette.primary.main}33`,
      }
    },
    linkActive: {
      borderBottom: `5px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
    sectionDesktop: {
      margin: 'auto 1.2rem',
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  })
)

const NavBar = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null)
  const [openModalAccount, setOpenModalAccount] = React.useState(false);

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const addClassName = ({ isCurrent }: LinkProps) => ({
    className: `${classes.link} ${isCurrent ? classes.linkActive : ''}`
  })

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()

  }

  const handleMenuCloseAccount = () => {
    handleOpenModal()
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleOpenModal = () => {
    setOpenModalAccount(true)
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      <MenuItem onClick={handleMenuCloseAccount}>My account</MenuItem>
      <MenuItem onClick={handleMenuClose}><span>Logout</span></MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <Mail />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <Notifications />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  )

  return (
    <div className={classes.root}>
      <AppBar position="relative" color="inherit">
        <Toolbar className={classes.toolbar}>
          <Link to="/">
            <img className={classes.img} src={Logo} alt="logo" />
          </Link>
          <Link to="/dashboard" getProps={addClassName}>Dashboard</Link>
          <Link to="/rating" getProps={addClassName}>Get a Quote</Link>

          <div className={classes.root} />
          <div className={classes.sectionDesktop}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="secondary"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreVert />
            </IconButton>
          </div>
        </Toolbar>
        <AccountModal
          isOpen={openModalAccount}
          setIsOpen={setOpenModalAccount}
        />
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  )
}

export default NavBar

import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { MenuItem, FormControl, Select } from '@material-ui/core'
import { LocalShipping } from '@material-ui/icons'
import getControlProviderGroup from '../services/getControlProviderGroup'

import useRatingForm from '../hooks/useRatingForm'

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
  }),
)

interface Props {
  listControProvidersGroup: ControlProvidersGroup
}

const SelectCPG = () => {
  const { params, handleParamsChange } = useRatingForm()
  const classes = useStyles()
  const [controlProvidersGroupList, setControlProvidersGroupList] = useState<any[]>();
  
  const controlProviderGroupList = () => getControlProviderGroup()
  .then((result) => {
    setControlProvidersGroupList(result)
  })
  .catch(err => {
    console.error(err)
  })

  useEffect(()=>{
    controlProviderGroupList()
  }, []);

  return (
    <div>
      <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <Select
          startAdornment={<LocalShipping color="secondary" />}
          id="select-equipment-type"
          value={params.code}
          onChange={handleParamsChange}
          name="code"
        >
          {
            controlProvidersGroupList?.map(({ code, name }) => <MenuItem key={code} value={code}>{name}</MenuItem>)
          }
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectCPG

import { useEffect, useReducer, useState, useContext } from "react";
import { useQuery } from "react-query";

import { getQuotes } from '../services/getQuotes';
import saveSearch from "../services/saveSearch";
import getAllAvailableConnectors from "../services/getAllAvailableConnectors";
import { getMiles } from "../helpers";
import getControlProvider from "../services/getControlProvider";
import postConnectCarrier from "../services/postConnectCarrier";
import postContactCarrier from "../services/postContactCarrier";
import getContactEmail from "../services/getContactEmail";
import { UserContext } from '../context/User';

interface State {
  isLoading: boolean;
  quotes: Quote[];
  errorQuotes: Quote[];
}

const initialState = {
  isLoading: false,
  quotes: [],
  errorQuotes: [],
};

const reducer = (state: State, action: QuotesAction) => {
  switch (action.type) {
    case "MAKE_REQUEST":
      return { isLoading: true, quotes: [], errorQuotes: [] };
    case "SET_QUOTES":
      return { ...state, quotes: [...state.quotes, ...action.payload.data] };
    case "SET_ERROR_QUOTES":
      return {
        ...state,
        errorQuotes: [...state.errorQuotes, action.payload.data],
      };
    case "STOP_LOADING":
      return { ...state, isLoading: false };
    case "SORT_BY_PRICE":
      return {
        ...state,
        quotes: [
          ...state.quotes.sort(
            (a, b) =>
              a.response.totalCharge.value - b.response.totalCharge.value
          ),
        ],
      };
    case "SORT_BY_EBOL":
      return {
        ...state,
        quotes: [
          ...state.quotes.sort((a, b) =>
            a.response.ebol === b.response.ebol ? 0 : a.response.ebol ? -1 : 1
          ),
        ],
      };
    case "SORT_BY_TRANSIT_DAYS":
      return {
        ...state,
        quotes: [
          ...state.quotes.sort(
            (a, b) => a.response.transitDay.days - b.response.transitDay.days
          ),
        ],
      };
    default:
      return state;
  }
};

const useQuotes = () => {
  const { user: { user: { user } } } = useContext(UserContext);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [ratingParams, setRatingParams] = useState<SearchParams | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [isRequestQuote, setRequestQuote] = useState(false);
  const [controlProviders, setControlProviders] = useState<any[]>();
  const [isFetchError, setIsFetchError] = useState(false);
  const data: any = useQuery(
    "allAvailableConnectors",
    getAllAvailableConnectors,
    {
      staleTime: 24 * 60 * 60 * 1000,
    }
  );

  let connectorsFTL: any[] = [];
  if (data.data?.length) {
    connectorsFTL = data.data ?
      data.data.filter((connector: any) => connector.type === "FTL") :
      [];
  }

  const listProviders = () => getControlProvider().
    then((result) => {
      setControlProviders(result)
    }).
    catch(err => {
      console.error(err)
    });

  let connectorsApproved: any[] = [];
  let connectorsPending: any[] = [];
  if (controlProviders?.length) {
    connectorsApproved = controlProviders ?
      controlProviders?.filter(
        (connector: any) => connector.state === "APPROVE"
      ) :
      [];

    connectorsPending = controlProviders ?
      controlProviders.filter(
        (connector: any) => connector.state === "PENDING"
      ) :
      [];
  }

  const connectorsPendingData = connectorsFTL?.filter(
    ({ scac_code: id1 }) =>
      connectorsPending.some(({ scac_code: id2 }) => id2 === id1)
  );

  let connectors: any[] = [];
  if (connectorsApproved?.length) {
    connectors = connectorsApproved?.map((a: any) => a.scac_code);
  }

  const connectorsWithoutAccess = connectorsFTL?.filter(
    ({ scac_code: id1 }) =>
      !controlProviders?.some(({ scac_code: id2 }) => id2 === id1)
  );

  const handleRequest = (
    {
      equipmentType,
      linearFeet,
      numberSkids,
      totalweight,
      linearTotalFeet,
      freightInformationLTL,
      code,
      name,
      _id,
      ...shipmentDetailsObj
    }: RatingParams
  ) => {
    const searchId = "TestingFromReact" + Math.random().toString();
    const shipmentDetails = {
      ...shipmentDetailsObj,
      miles: getMiles(
        shipmentDetailsObj.origin,
        shipmentDetailsObj.destination
      ),
    };
    const body = {
      shipmentDetails,
      freightInformation: {
        equipmentType,
        linearFeet,
        numberSkids,
        totalweight,
        linearTotalFeet
      },
      freightInformationLTL,
      capacityProviderGroup: {
        code,
        name,
        _id,
      }
    };
    return (
      !state.isLoading &&
      saveSearch(
        {
          searchId,
          body
        }
      ).
        then((response) => { return setRatingParams(response) })
    );
  };

  const handleConnect = (code: any) => {
    postConnectCarrier({
      scac_code: code
    })
      .then((response) => {
        if (response.status === 200) {
          setIsConnected(true);
          setIsFetch(true);
        } else {
          setIsConnected(false);
          setIsFetch(true);
        }
        if (response.status === 422) {
          setIsFetchError(true)
        }
      })
      .catch((err) => {
        console.error(err);
        setIsConnected(false);
      });
  };

  const handleContact = (scacCode: any, ratingParams: any) => {
    const from = `${user.givenName} ${user.familyName}`;
    const account = user.email;

    getContactEmail({ account, scacCode })
      .then((response) => {
        postContactCarrier({
          body: ratingParams,
          scacCode,
          subject: '',
          to: response.support_mail,
          from,
          contactInfo: response.contact_info,
        })
          .then((response) => {
            if (response.status === 200) {
              setIsFetch(true);
            } else {
              setIsFetch(false);
            }
          })
          .catch((err) => {
            console.error(err);
          });

      })
  };

  useEffect(() => {
    listProviders();
    if (!ratingParams) return;
    dispatch({ type: "MAKE_REQUEST" });
    if (ratingParams.body.shipmentDetails.mode === 'LTL' ||
      ratingParams.body.shipmentDetails.mode === 'VLTL') {
      (async () => {
        setRequestQuote(true);
        if (connectors.length) {
          const connectorId = '';
          getQuotes({ connectorId, ratingParams })
            .then((quoteResponse) => {
              dispatch(
                {
                  type: "SET_QUOTES",
                  payload: {
                    data: quoteResponse.filter((quote: any) =>
                      quote.success).map((quote: any) => ({
                        ...quote,
                      }))
                  }
                }
              )
              dispatch(
                {
                  type: "SET_ERROR_QUOTES",
                  payload: { data: quoteResponse },
                }
              )
            }
            )
            .then(() => dispatch({ type: "STOP_LOADING" }))
            .catch((err) => {
              console.error(err);
              dispatch({ type: "STOP_LOADING" });
            })
        } else {
          dispatch({ type: "STOP_LOADING" });
        }
      })();
    } else {
      (async () => {
        setRequestQuote(true);
        if (connectors.length) {
          connectors.map((connectorId: any) =>
            getQuotes({ connectorId, ratingParams })
              .then((quoteResponse) =>
                Array.isArray(quoteResponse)
                  ? dispatch({
                    type: "SET_QUOTES",
                    payload: {
                      data: quoteResponse.map((quote) => ({
                        ...quote,
                      })),
                    },
                  })
                  : quoteResponse.response &&
                  dispatch({
                    type: "SET_ERROR_QUOTES",
                    payload: { data: quoteResponse },
                  })
              )
              .then(() => dispatch({ type: "STOP_LOADING" }))
              .catch((err) => {
                console.error(err);
                dispatch({ type: "STOP_LOADING" });
              })
          );
        } else {
          dispatch({ type: "STOP_LOADING" });
        }
      })();
    }

    // eslint-disable-next-line
  }, [ratingParams]);

  return {
    state,
    ratingParams,
    connectorsWithoutAccess,
    connectorsPendingData,
    handleSort: (action: QuotesAction) => dispatch(action),
    handleRequest,
    handleConnect,
    handleContact,
    isConnected,
    isFetch,
    isRequestQuote,
    isFetchError,
    setIsFetchError,
  };
};

export default useQuotes;

import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography, TableCell, TableRow, Tooltip } from '@material-ui/core'

import BookingModal from './BookingModal'
import { Check, Info } from '@material-ui/icons'

interface Props {
  quote: Quote
  ratingParams: SearchParams | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      border: '1px solid #CDCDCD',
      boxShadow: '0 0 black',
      backgroundColor: theme.palette.background.paper,
      margin: '0.25rem 0',
      padding: theme.spacing(1, 4),
      minHeight: '140px',
      maxHeight: '350px'
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '120px',
      maxHeight: '32px',
    },
    center: {
      flexDirection: 'column',
      margin: '0auto'
    },
    textWithIcon: {
      display: "flex",
      alignItems: "center",
    },
    imageContainer:{
      margin: '12px auto',
      width: '120px',
    },
    scacCode:{
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#6A3DF3'
    }
  })
)

const Quote = ({ quote, ratingParams }: Props) => {
  const classes = useStyles()

  return (
    <>
      <TableRow>
        <TableCell scope="row">
          <div className={classes.imageContainer}>
            <Typography className={classes.scacCode}>{quote.response.alternativeScacCode}</Typography>
            <img className={classes.img} src={quote.response.logo} alt={quote.response.scacCode} />
          </div>
        </TableCell>
        <TableCell align="center" scope="row">
            {quote.response.responseTime}
        </TableCell>
        <TableCell align="center" scope="row">
          {quote.response.ebol ?
            <>
              <Check style={{ marginRight: '0.25rem', fontSize: 16, color: '#00854A' }} />
            </> :
            ''
          }
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">
            {quote.response.transitDay.days ?
              <>
                {`${quote.response.transitDay.days} days`}
                <Tooltip arrow placement="top" title={quote.response.transitDay.condition} >
                  <Info style={{ marginLeft: '0.25rem', fontSize: 16, verticalAlign: 'middle', }} color="secondary" />
                </Tooltip>
              </>
              :
              ''
            }
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography className={classes.center} variant="h5">${Intl.NumberFormat().format(quote.response.totalCharge.value)}</Typography>
        </TableCell>
        <TableCell align="right"><BookingModal quote={quote} ratingParams={ratingParams} /></TableCell>
      </TableRow>
    </>
  )
}

export default Quote

import React, { useState, useEffect } from 'react'
import { getLoginWithTokenUserData } from '../services/getLogin'

type User = {
  auth: string
  user: {
    user: AccountInformation
  }
  ok?: boolean
}

type UserContextType = {
  user: User,
  setUser: React.Dispatch<React.SetStateAction<User>>
}

const initialUser = {
  extension: '',
  alternativeEmail: '',
  companyAddresLineOptional: '',
  companyAddress: '',
  companyName: '',
  companyStateProvinceRegion: '',
  companyZipCode: '',
  email: '',
  familyName: '',
  givenName: '',
  phoneNumber: '',
  tenantId: 0,
  companyWebsite: '',
  toc: false,
  duns: 0,
  companyCity: '',
  companyContactEmail: '',
  companyContactPerson: '',
  companyContactPhone: '',
  state: false,
  createAt: new Date(),
  modifiedAt: new Date(),
}

const UserContext = React.createContext<UserContextType>(
  {
    user: {
      auth: 'loading',
      user: {
        user: initialUser
      }
    },
    setUser: () => { }
  }
);

async function getLogin(setUser: any) {
  const userFetched: any = await getLoginWithTokenUserData();
  setUser(userFetched);
}

function ContextProvider({ children }: any) {
  const [user, setUser] = useState<User>(
    {
      auth: 'loading',
      user: {
        user: initialUser
      }
    }
  );

  useEffect(() => {
    if (user.auth === 'loading') {
      getLogin(setUser);
    }
  });

  return (
    <UserContext.Provider value={{user, setUser}}>
      {children}
    </UserContext.Provider>
  );
};

export {
  ContextProvider,
  UserContext,
};

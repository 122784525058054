import { useState, useContext } from "react";
import { UserContext } from '../context/User';

const useAccount = () => {
  const [isValidEmail, setValidEmail] = useState(false);

  const {
    setUser,
    user: {
      user,
      user: { user: account },
      auth,
      ok
    }
  } = useContext(UserContext);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    var mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (name === "alternativeEmail" && value.match(mailformat)) {
      setValidEmail(false);
    } else {
      if (name === "alternativeEmail") {
        setValidEmail(true);
      }
    }

    const userUpdated = {
      ...user,
      user: {
        ...account,
        [name]: value
      }
    };

    setUser({ auth, ok, user: userUpdated });
  };

  return { handleInputChange, isValidEmail };
};

export default useAccount

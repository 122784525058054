import React, { useContext } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
  Backdrop,
  Button,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography
} from '@material-ui/core'

import useAccount from '../hooks/useAccount'
import saveAccount from "../services/saveAccount";
import { getAccount } from "../services/getAccount";
import { UserContext } from '../context/User';

interface Props {
  isOpen: boolean,
  setIsOpen: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4, 4, 4, 4),
      width: '70%',
      minWidth: '320px',
      maxWidth: '900px'
    },
    button: {
      width: '100%',
      margin: '1rem auto',
    },
    center: {
      flexDirection: 'column',
      margin: '0 auto'
    },
    form: {
      width: '100%'
    },
    triggerModal: {
      display: 'inline-block'
    }
  }),
);

const AccountModal = ({ isOpen, setIsOpen }: Props) => {
  const classes = useStyles();
  const { user: { user: { user: account } } } = useContext(UserContext);
  const {
    handleInputChange,
    isValidEmail
  } = useAccount();

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    getAccount().then(response => {
      saveAccount(account).then(response => {
        setIsOpen(false);
      });
    });
  };

  const handleClose = () => {
    setIsOpen(false)
  };

  return (
    <div className={classes.center}>
      <Modal
        disableBackdropClick
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <form className={classes.form} onSubmit={handleOnSubmit}>
              <Grid container>
                <Grid item container sm={12} lg={12} md={12} spacing={2}>
                  <Typography
                    style={{ marginBottom: '2rem', width: '100%' }}
                    variant="h5">
                    Add Your Individual Contact Information and Company Info
                      <p style={
                      {
                        color: "gray",
                        fontSize: '16px',
                        fontWeight: 'bold',
                        display: 'inline-block'
                      }
                    }
                    >
                      You only need to this once.
                    </p>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item container sm={12} lg={12} spacing={2}>


                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="subtitle1">Name</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="givenName"
                      type="text"
                      fullWidth
                      variant="outlined"
                      label="Given Name"
                      name="givenName"
                      value={account.givenName}
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="familyName"
                      type="text"
                      fullWidth
                      variant="outlined"
                      label="Family Name"
                      name="familyName"
                      value={account.familyName}
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="subtitle1">Email</Typography>
                    <TextField
                      id="email"
                      type="text"
                      fullWidth
                      variant="outlined"
                      name="email"
                      disabled={true}
                      value={account.email}
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="subtitle1">
                      Best Contact Email
                    </Typography>
                    <TextField
                      id="alternativeEmail"
                      type="email"
                      fullWidth
                      variant="outlined"
                      name="alternativeEmail"
                      value={account.alternativeEmail}
                      onChange={handleInputChange}
                      required
                      error={isValidEmail}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1">Phone Number</Typography>
                    <TextField
                      id="phoneNumber"
                      type="text"
                      fullWidth
                      variant="outlined"
                      name="phoneNumber"
                      value={account.phoneNumber}
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="subtitle1">Extension</Typography>
                    <TextField
                      id="extension"
                      type="text"
                      fullWidth
                      label="(Optional)"
                      variant="outlined"
                      name="extension"
                      value={account.extension}
                      onChange={handleInputChange}
                    />
                  </Grid>


                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="subtitle1">Company Info</Typography>
                  </Grid>


                  <Grid item xs={12} sm={6} md={6}>

                    <TextField
                      id="companyName"
                      type="text"
                      fullWidth
                      variant="outlined"
                      name="companyName"
                      value={account.companyName}
                      onChange={handleInputChange}
                      required
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="companyWebsite"
                      type="text"
                      fullWidth
                      variant="outlined"
                      label="Website"
                      name="companyWebsite"
                      value={account.companyWebsite}
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>

                  <Grid item xs={8} sm={8} md={8}>
                    <TextField
                      id="companyAddress"
                      type="text"
                      fullWidth
                      variant="outlined"
                      label="Company Headquarter Address Line 1"
                      name="companyAddress"
                      value={account.companyAddress}
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>

                  <Grid item xs={4} sm={4} md={4}>
                    <TextField
                      id="duns"
                      type="number"
                      fullWidth
                      variant="outlined"
                      label="DUNS"
                      name="duns"
                      value={account.duns}
                      onChange={handleInputChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="companyAddresLineOptional"
                      type="text"
                      fullWidth
                      label="Address Line 2 (Optional)"
                      variant="outlined"
                      name="companyAddresLineOptional"
                      value={account.companyAddresLineOptional}
                      onChange={handleInputChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      id="companyStateProvinceRegion"
                      type="text"
                      fullWidth
                      label="State"
                      variant="outlined"
                      name="companyStateProvinceRegion"
                      value={account.companyStateProvinceRegion}
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      id="companyZipCode"
                      type="text"
                      fullWidth
                      variant="outlined"
                      label="Zip Code"
                      name="companyZipCode"
                      value={account.companyZipCode}
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>

                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-end">
                    <Grid item>
                      <Button
                        disabled={isValidEmail}
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large">
                        Save
                                            </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  )
};

export default AccountModal

import React, { useEffect, useState } from 'react'
import { Button, createStyles, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core'

import AdditionalCarrier from './AdditionalCarrier'
import { limitResultsEmpty } from '../helpers'
import ErrorQuote from '../components/ErrorQuote'

interface Props {
  additionalCarriers: Quote[]
  quoteErrors: Quote[]
  setAdditionalCarrierLength: any
  ratingParams: SearchParams | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: '540px',
      margin: '1rem auto',
      [theme.breakpoints.down('sm')]: {
        width: '240px',
      },
    },
    title: {
      marginBottom: '0.25rem',
      fontWeight: 600,
    }
  })
)

const AdditionalCarriersList = ({ ratingParams, setAdditionalCarrierLength, additionalCarriers, quoteErrors }: Props) => {
  const classes = useStyles()
  const [limitAdditionalCarriers, setLimitAdditionalCarriers] = useState(true)
  const [errorCarriersList, showMoreButton] = limitResultsEmpty(quoteErrors, limitAdditionalCarriers)

  useEffect(()=>{
    setAdditionalCarrierLength(additionalCarriers.length + errorCarriersList.length)
  },[additionalCarriers.length, errorCarriersList.length, setAdditionalCarrierLength])

  return (
    <>
      {
        additionalCarriers.map(quote => <AdditionalCarrier key={quote.scac_code} quote={quote} />)
      }
      { 
        errorCarriersList.map(errorQuote => <ErrorQuote ratingParams={ratingParams} key={errorQuote.response.scacCode} quote={errorQuote} />)
      }
      {
        showMoreButton &&
        <TableRow>
          <TableCell align="center" colSpan={7} scope="row">
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={() => setLimitAdditionalCarriers(!limitAdditionalCarriers)}
            >
              {limitAdditionalCarriers ? 'Show More ' : 'Hide All'}
            </Button>
          </TableCell>
        </TableRow>
      }
      
    </>
  )
}

export default AdditionalCarriersList

import React, { useState } from 'react'
import { Typography, Select, MenuItem, Grid, makeStyles } from '@material-ui/core'

import Spinner from '../assets/spinner.svg'
import SearchingQuotes from '../assets/searching-quotes.svg'

interface Props {
  resultsNumber: number
  isLoading: boolean
  ratingParams: SearchParams | null
  handleSort: ({ type }: QuotesAction) => void
}

const useStyles = makeStyles((theme) => ({
  "@keyframes rotate": {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' }
  },
  spinner: {
    margin: '0 1rem',
    animation: `$rotate 1.6s linear infinite`
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}))

const QuotesFilter = ({ resultsNumber, isLoading, ratingParams, handleSort }: Props) => {
  const classes = useStyles()
  const [sortCriteria, setSortCriteria] = useState('')

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const sortValue = event.target.value as SortQuotesAction
    setSortCriteria(sortValue)
    handleSort({ type: sortValue })
  }

  return (
    <Grid item container style={{ padding: '3rem 3rem 0 3rem' }}>
      <Grid item xs={4}>
        {
          ratingParams && <div>
            <Typography variant="h5" style={{ fontWeight: 600 }}>
              {`${resultsNumber} Result${resultsNumber > 1 ? 's' : ''}`}
            </Typography>
            <Typography variant="subtitle2" style={{ marginBottom: '6px' }}>
              {
                `
                    ${ratingParams?.body.shipmentDetails.origin.zipCode} 
                    ${ratingParams?.body.shipmentDetails.origin.CityName}, 
                    ${ratingParams?.body.shipmentDetails.origin.StatCode} to 
                    ${ratingParams?.body.shipmentDetails.destination.zipCode} 
                    ${ratingParams?.body.shipmentDetails.destination.CityName}, 
                    ${ratingParams?.body.shipmentDetails.destination.StatCode}
                  `
              }
            </Typography>
          </div>
        }
      </Grid>
      <Grid item xs={4}>
        {
          isLoading && <div className={classes.center}>
            <img src={Spinner} alt="spinner" className={classes.spinner} />
            <img src={SearchingQuotes} alt="searching for quotes" />
          </div>
        }
      </Grid>
      <Grid item container xs={4} alignContent="flex-end" justify="flex-end">
        {
          ratingParams && <div>
            <span style={{ padding: '5px 4px 9px 0' }}>Sort: </span>
            <Select value={sortCriteria} onChange={handleChange} disableUnderline displayEmpty>
              <MenuItem value="" disabled>By Criteria</MenuItem>
              <MenuItem value="SORT_BY_PRICE">Price</MenuItem>
              <MenuItem value="SORT_BY_EBOL">eBOL</MenuItem>
              <MenuItem value="SORT_BY_TRANSIT_DAYS">Transit Days</MenuItem>
            </Select>
          </div>
        }
      </Grid>
    </Grid>
  )
}

export default QuotesFilter

import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Modal, Backdrop, Fade, Button, Grid, Typography, CircularProgress } from '@material-ui/core'

import { carrierLogo, equipmentTypeList } from '../helpers'
import useBooking from '../hooks/useBooking'
import TenderModal from '../components/TenderModal';
import moment from 'moment'

interface Props {
  quote: Quote
  ratingParams: SearchParams | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4, 7),
      width: '40%',
      minWidth: '320px'
    },
    button: {
      maxWidth: '120px',
      width: '100%',
      margin: '0 auto',
    },
    textWithIcon: {
      display: "flex",
      alignItems: "center",
      color: '#00854A',
      justifyContent: 'center',
    },
    center: {
      flexDirection: 'column',
      margin: '1rem auto',
    },
    carrierImg: {
      display: 'block',
      minWidth: '140px',
      maxWidth: '40%',
      margin: '2rem 0'
    },
    table: {
      backgroundColor: '#FFFFFF',
      padding: '3rem',
    },
    submit: {
      padding: theme.spacing(2, 4),
      margin: theme.spacing(4, 0),
    },
    modalButtons: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '40px',
    }
  }),
)

const BookingModal = ({ quote, ratingParams }: Props) => {
  const classes = useStyles()
  const { isLoading, isBooked, handleBooking, open, setOpen } = useBooking()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        className={classes.button}
        disabled={isBooked}
        variant="outlined"
        color="primary"
        onClick={handleOpen}>
        Tender
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <div className={classes.paper}>
            {
              isBooked && <TenderModal carrierName={quote.response.scacCode}/>
            }
            {
              isBooked && <div className={classes.modalButtons}>
                <Button variant="contained" color="primary" style={{ width: '6rem', height: '2.5rem' }} onClick={handleClose}>
                  DONE
                </Button>
              </div>
            }
            {
              (!isBooked && !isLoading) && <div>
                <Typography variant="h5">
                  <b>Confirm Tender Details</b>
                </Typography>
                <img className={classes.carrierImg} src={carrierLogo(quote.response.scacCode)} alt={quote.response.scacCode} />
                <Grid container spacing={2} className={classes.table}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">Carrier</Typography>
                    <Typography variant="body2">{quote.response.scacCode}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">Total Weight</Typography>
                    <Typography variant="body2">{Intl.NumberFormat().format(ratingParams ? +ratingParams.body.freightInformation.totalweight : 0)} lb</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">Shipment Date</Typography>
                    <Typography variant="body2">{moment(quote.response.pickupDateTime.split('T')[0]).format('MM/DD/YYYY')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">Equipment Type</Typography>
                    <Typography variant="body2">
                      {
                        equipmentTypeList.find(({ symbol }) => symbol === ratingParams?.body.freightInformation.equipmentType)?.name
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">Origin</Typography>
                    <Typography variant="body2">
                      {
                        `
                      ${ratingParams?.body.shipmentDetails.origin.CityName}, 
                      ${ratingParams?.body.shipmentDetails.origin.StatCode} 
                      ${ratingParams?.body.shipmentDetails.origin.zipCode}
                    `
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">Number of Skids</Typography>
                    <Typography variant="body2">{ratingParams?.body.freightInformation.numberSkids || '-'}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">Destination</Typography>
                    <Typography variant="body2">
                      {
                        `
                      ${ratingParams?.body.shipmentDetails.destination.CityName}, 
                      ${ratingParams?.body.shipmentDetails.destination.StatCode} 
                      ${ratingParams?.body.shipmentDetails.destination.zipCode}
                    `
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">Linear Feet</Typography>
                    <Typography variant="body2">{ratingParams?.body.freightInformation.linearFeet || '-'}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">Quote ID</Typography>
                    <Typography variant="body2">#{quote.response.quoteId || '-'}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">Price</Typography>
                    <Typography variant="body2">${Intl.NumberFormat().format(quote.response.totalCharge.value)}</Typography>
                  </Grid>
                </Grid>
                <div style={{ justifyContent: 'flex-end', display: 'flex', borderTop: 'groove', borderWidth: 'thin' }}>
                <Button onClick={handleClose} color="primary" size="large" className={classes.submit}><b>CANCEL</b></Button>
                <Button
                  onClick={() => ratingParams && handleBooking({ quote, ratingParams })}
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.submit}>
                  <b>CONFIRM</b>
                </Button>
                </div>
              </div>
            }
            {
              isLoading && <div className={classes.textWithIcon}>
                <CircularProgress style={{ margin: '2rem auto' }} />
              </div>
            }
            {
              isBooked && <div style={{ margin: '2rem' }}>
              </div>
            }
          </div>
        </Fade>
      </Modal>
    </>
  )
};

export default BookingModal

import { API_URL } from './settings'

const saveSearch = async ({ body, searchId }: SearchParams) => {
  return fetch(
    `${API_URL}/api/v1/search/save?searchId=${searchId}`,
    {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      },
    }
  ).
  then(() => ({ body, searchId }))
}

export default saveSearch

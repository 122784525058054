import { useEffect, useState } from 'react'

import bookShipment from '../services/bookShipment'

interface Props {
  quote: Quote
  ratingParams: SearchParams
}

const useBooking = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isBooked, setIsBooked] = useState(false)
  const [bookingData, setBookingData] = useState<BookingData | null>(null)
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false);
  }

  const handleBooking = ({ quote, ratingParams: { body, searchId } }: Props) =>
      setBookingData({
          data: {
              ...quote,
              ...body,
              connector: {
                  scac_code: quote.response.scacCode,
                  type: quote.response.mode
              }
          },
          searchId,
          state: 'BOOKED',
          subject: 'Booking',

      });

  useEffect(() => {
    if (!bookingData) return

    setIsLoading(true)
    bookShipment(bookingData)
      .then(() => {
        setIsBooked(true);
        setIsLoading(false);

      })
      .catch(err => {
        console.error(err)
        setIsLoading(false);
      })

  }, [bookingData]);

  return { isLoading, isBooked, handleBooking, open, setOpen,handleClose }
};

export default useBooking

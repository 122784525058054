import React, { useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip
} from '@material-ui/core'
import { Info } from '@material-ui/icons'

import RatingForm from '../components/RatingForm'
import QuotesFilter from '../components/QuotesFilter'
import QuoteList from '../components/QuoteList'
import AdditionalCarriersList from '../components/AdditionalCarriersList'
import useQuotes from '../hooks/useQuotes'
import NavBar from "../components/NavBar";
import QuotePendingList from '../components/QuotePendingList';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#FAFAFA',
    color: '#575451',
    fontWeight: 600,
    textAlign: 'center',
    padding: '12px 24px 12px 16px',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableWrapper: {
    border: 0,
    marginBottom: '40px',
  },
});

const Rating = () => {
  const {
    state,
    ratingParams,
    handleRequest,
    handleSort,
    connectorsWithoutAccess,
    connectorsPendingData,
    isRequestQuote
  } = useQuotes()

  const classes = useStyles();
  const [addtionalCarrierLength, setAdditionalCarrierLength] = useState(0);

  return (
    <>
      <header><NavBar></NavBar></header>
      <RatingForm onSubmit={handleRequest} />
      <QuotesFilter
        resultsNumber={state.quotes.length +
          addtionalCarrierLength +
          connectorsPendingData.length
        }
        isLoading={state.isLoading}
        handleSort={handleSort}
        ratingParams={ratingParams}
      />
      <div style={{ padding: '0 3em' }}>
        <TableContainer className={classes.tableWrapper} component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Carries</StyledTableCell>
                <StyledTableCell>
                  Response Time
                    <Tooltip arrow placement="top" title={<>{"Carrier's Response Time requires tender confirmation "} <br /> {"within the time listed during business hours."}</>}>
                    <Info
                      style={
                        {
                          marginLeft: '0.25rem',
                          fontSize: 16,
                          verticalAlign: 'middle'
                        }
                      }
                      color="secondary"
                    />
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell>
                  eBOL
                  <Tooltip
                    arrow placement="top"
                    title="carrier supports electronic bill of landing"
                  >
                    <Info
                      style={
                        {
                          marginLeft: '0.25rem',
                          fontSize: 16, verticalAlign: 'middle'
                        }
                      }
                      color="secondary"
                    />
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell>
                  Transit Days
                </StyledTableCell>
                <StyledTableCell>Quote</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <QuoteList quotes={state.quotes} ratingParams={ratingParams} />
              {
                (state.quotes.length || isRequestQuote) &&
                ratingParams?.body.shipmentDetails.mode === 'FTL' &&
                <QuotePendingList quotes={connectorsPendingData} />
              }
              {
                (state.quotes.length || isRequestQuote) &&
                ratingParams?.body.shipmentDetails.mode === 'FTL' &&
                <AdditionalCarriersList
                  ratingParams={ratingParams}
                  setAdditionalCarrierLength={setAdditionalCarrierLength}
                  additionalCarriers={connectorsWithoutAccess}
                  quoteErrors={state.errorQuotes}
                />
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  )
};

export default Rating

import React from 'react'

import PendingQuote from './PendingQuote'

interface Props {
  quotes: Quote[]
}

const QuotePendingList = ({ quotes }: Props) => {
  const quotePendingList = quotes
  
  return (
    <>
      {
        Boolean(quotes.length) && quotePendingList.map(quote => <PendingQuote
          key={quote.scac_code + Math.random()}
          quote={quote}
        />)
      }
    </>
  )
}

export default QuotePendingList

import React, { useState } from 'react'
import { Button, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core'

import RecentBooking from './RecentBooking'
import ProgressBar from './ProgressBar'
import { limitResults } from '../helpers'

interface Props {
  bookings: RecentSearch[]
  isLoading: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: '540px',
      margin: '1rem auto',
      [theme.breakpoints.down('sm')]: {
        width: '240px',
      },
    },
    my: {
      marginTop: '2rem',
      marginBottom: '2rem',
    }
  })
)

const RecentBookingList = ({ isLoading, bookings }: Props) => {
  const classes = useStyles()
  const [limitBookings, setLimitBookings] = useState(true)
  const [recentBookings, showButton] = limitResults(bookings, limitBookings)

  return (
    <Grid className={classes.my} container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">Recent Bookings</Typography>
      </Grid>
      {
        isLoading
          ? <ProgressBar />
          : recentBookings.map(quote => <RecentBooking key={quote._id} quote={quote} />)
      }
      {
        showButton && <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={() => setLimitBookings(!limitBookings)}
        >
          {limitBookings ? 'Show All Bookings' : 'Hide Bookings'}
        </Button>
      }
    </Grid>
  )
}

export default RecentBookingList

import { API_URL } from './settings'

export const getAccount = () =>
  fetch(
    `${API_URL}/api/v1/account/getAccountByEmail`,
    {
      credentials: 'include'
    }
  ).
  then(res => res.json());

export const acceptTerms = async () => {
  return fetch(
    `${API_URL}/api/v1/account/toc`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).
    then(response => (response));
}

import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme({
  typography: {
    subtitle1: {
      "fontWeight": 600,
    },
    h4: {
      "fontWeight": 550,
    }
  },
  palette: {
    primary: {
      main: '#00558B'
    },
    secondary: {
      main: '#575451'
    },
  },
  overrides: {
    MuiButton: {
      outlined: {
        borderRadius: '0'
      },
      contained: {
        borderRadius: '0'
      },
    },
    MuiPaper: {
      elevation1: {
        border: '1px solid #CDCDCD',
        boxShadow: '0 0 black'
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '0'
      }
    }
  }
})

export default theme
import { API_URL } from './settings'

interface ContactCarrier {
  body: object,
  scacCode : string,
  subject: string,
  to: string,
  from: string,
  contactInfo: string,
}

const postContactCarrier = async (body: ContactCarrier) => fetch(
  `${API_URL}/api/v1/tender/contactcarrier`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(body)
  }
);

export default postContactCarrier

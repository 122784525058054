import React from 'react'
import { RouteComponentProps, Router } from '@reach/router'
import { ThemeProvider } from '@material-ui/core'

import { ContextProvider } from './context/User'
import theme from './theme'
import DashboardPage from './pages/Dashboard'
import RatingPage from './pages/Rating'
import LandingPage from './pages/Landing'
import HomePage from './pages/Home'
import RatingParamsContextProvider from './context/RatingParams'

const Home = (props: RouteComponentProps) => <HomePage />;
const Rating = (props: RouteComponentProps) => <RatingPage />;
const Dashboard = (props: RouteComponentProps) => <DashboardPage />;
const Landing = (props: RouteComponentProps) => <LandingPage />;


const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <RatingParamsContextProvider>
        <ContextProvider>
          <Router>
            <Home path="/" default />
            <Rating path="/rating" />
            <Dashboard path="/dashboard" />
            <Landing path="/landing" />
          </Router>
        </ContextProvider>
      </RatingParamsContextProvider>
    </ThemeProvider>
  )
};

export default App

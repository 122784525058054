import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core'

import iconConfirmed from '../assets/images/iconConfirmed.png'

interface Props {
    carrierName: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        center: {
            flexDirection: 'column',
            margin: '1rem auto',
        },
        img: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '50px'
        },
        paragraph1: {
            textAlign: 'center',
            fontSize: '25px',
        },
        paragraph2: {
            textAlign: 'center',
            marginBottom: '60px',
        },
        title: {
            textAlign: 'center',
        },
    }),
);

const TenderModal = ({ carrierName }: Props) => {
    const classes = useStyles();

    return (
            <div className={classes.center}>
                <div className={classes.img}>
                    <img src={iconConfirmed} alt="logo" style={{ width: '120px' }} />
                </div>

                <div className={classes.title}>
                    <Typography variant="h4" >Tender Complete</Typography>
                </div>

                <div className={classes.paragraph1}>
                    <p><b>{carrierName} will confirm the tender</b></p>
                </div>

                <div className={classes.paragraph2}>
                    <p>If this is your first collaboration with this carrier, they will confirm your account payable and invoice information via email.</p>
                </div>
            </div>
    )
};

export default TenderModal;

import { useQuery } from 'react-query'
import getRecentSearches from '../services/getRecentSearches'

const useRecentSearches = () => {
  const { data, isLoading } = useQuery(
    [
      'recenteSearches',
      {}
    ],
    getRecentSearches,
    {
      staleTime: 10000,
      cacheTime: 100000
    }
  )

  return {
    isLoading,
    quotes: data ? data.filter((item: RecentSearch) => item.state === 'QUOTED') : [],
    bookings: data ? data.filter((item: RecentSearch) => item.state === 'BOOKED') : []
  }
}

export default useRecentSearches

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Icon,
  Popover,
  FormLabel,
  Radio,
  RadioGroup
} from '@material-ui/core'

import {
  LocalShipping,
  FormatListNumbered,
  Info, DeleteForever
} from '@material-ui/icons'
import { useLocation } from '@reach/router'
import { useForm, useFieldArray, Controller } from "react-hook-form";

import LocationInput from './LocationInput'
import AccesorialsInput from './AccesorialsInput'
import useRatingForm from '../hooks/useRatingForm'
import ShipmentLogo from '../assets/shipment.svg'
import WeightIcon from '../assets/weight-pound.svg'
import SelectCPG from './SelectCPG'
import getControlProviderGroup from '../services/getControlProviderGroup';

interface Props {
  onSubmit: (params: RatingParams) => void
}

type State = {
  isRequote: boolean
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 6),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    margin: theme.spacing(4),
    position: 'relative',
  },
  submit: {
    padding: theme.spacing(2, 4),
    margin: theme.spacing(2, 0, 0),
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  boxInfo: {
    padding: '40px',
    maxWidth: '500px',
  },
  simpleList: {
    fontSize: '14px',
    listStyle: 'none',
    margin: '0',
    padding: '0',
    '& $li': {
      padding: '5px 0',
    },
  },
  typography: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  triggerPopup: {
    cursor: 'pointer',
    color: '#00558B',
    fontSize: '14px',
  },
  radioRow: {
    flexDirection: 'row',
  },
  selectType: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  deleteItemBtn: {
    background: 'transparent',
    border: 0,
  },
}))

const RatingForm = ({ onSubmit }: Props) => {
  const classes = useStyles()
  const { state } = useLocation()
  const {
    params,
    handleParamsChange,
    handleLocationChange,
    handleAccesorialsChange
  } = useRatingForm()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [controlProvidersGroupList, setControlProvidersGroupList] = useState<any[]>();

  const freightClassList = [
    { value: "55", text: "55" },
    { value: "60", text: "60" },
    { value: "65", text: "65" },
    { value: "70", text: "70" },
    { value: "77.5", text: "77.5" },
    { value: "85", text: "85" },
    { value: "92.5", text: "92.5" },
    { value: "100", text: "100" },
    { value: "110", text: "110" },
    { value: "125", text: "125" },
    { value: "150", text: "150" },
    { value: "175", text: "175" },
    { value: "200", text: "200" },
    { value: "250", text: "250" },
    { value: "300", text: "300" },
    { value: "400", text: "400" },
    { value: "500", text: "500" },
  ]

  const controlProviderGroupList = () => getControlProviderGroup()
    .then((result) => {
      setControlProvidersGroupList(result)
    })
    .catch(err => {
      console.error(err)
    })

  const onRequest = (data: any) => {
    onSubmit({ ...params, freightInformationLTL: data.freightInformationLTL })
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    controlProviderGroupList();
    const ratingState = state as State
    const isRequote = ratingState?.isRequote
      && params.origin.zipCode
      && params.destination.zipCode
      && params.totalweight

    if (isRequote) onSubmit(params)
    // eslint-disable-next-line
  }, [])

  const { register, control, handleSubmit } = useForm({
    defaultValues: {
      freightInformationLTL: [{
        lenght: "",
        width: "",
        height: "",
        weight: "",
        freightClass: "",
      }]
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "freightInformationLTL"
  });

  return (
    <div className={classes.paper}>
      <form className={classes.form} onSubmit={handleSubmit(onRequest)}>
        <Typography style={{ marginBottom: '2rem' }} variant="h4">Get a Quote</Typography>
        <Grid container spacing={4}>
          <Grid item container sm={12} lg={9} spacing={2}>
            {
              controlProvidersGroupList && controlProvidersGroupList?.length > 0 &&
              <Grid item xs={12}>
                <div className={classes.selectType}>
                  <FormLabel component="legend">Select one</FormLabel>
                  <RadioGroup className={classes.radioRow} aria-label="gender" name="mode" value={params.mode} onChange={handleParamsChange}>
                    <FormControlLabel value="FTL" control={<Radio />} label="FTL" />
                    <FormControlLabel value="LTL" control={<Radio />} label="LTL" />
                    <FormControlLabel value="VLTL" control={<Radio />} label="VLTL" />
                  </RadioGroup>
                </div>
              </Grid>
            }
            {
              (params.mode === 'LTL' || params.mode === 'VLTL') &&
              <Grid item xs={12}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle1">Capacity Provider Account Group</Typography>
                  <SelectCPG />
                </Grid>
              </Grid>
            }
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle1">Shipment Date</Typography>
              <TextField
                id="date"
                type="date"
                fullWidth
                variant="outlined"
                name="shipDate"
                value={params.shipDate}
                onChange={handleParamsChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle1">Origin</Typography>
              <LocationInput handleChange={handleLocationChange('origin')} currentLocation={params.origin} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle1">Destination</Typography>
              <LocationInput handleChange={handleLocationChange('destination')} currentLocation={params.destination} />
            </Grid>

            {
              params.mode === 'FTL' &&
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="subtitle1">Equipment Type</Typography>
                  <FormControl fullWidth variant="outlined">
                    <Select
                      startAdornment={<LocalShipping style={{ marginRight: '0.5rem' }} />}
                      id="select-equipment-type"
                      name="equipmentType"
                      value={params.equipmentType}
                      onChange={handleParamsChange}
                    >
                      <MenuItem value={'DV'}>Dry Van</MenuItem>
                      <MenuItem value={'RF'}>Reefer</MenuItem>
                      <MenuItem value={'FL'}>Flatbed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Typography variant="subtitle1">Total Weight</Typography>
                  <TextField
                    id="total-weight"
                    required
                    InputProps={{
                      inputProps: {
                        min: 0
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon>
                            <img src={WeightIcon} alt="Weight Icon" />
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    variant="outlined"
                    type="number"
                    name="totalweight"
                    value={params.totalweight}
                    onChange={handleParamsChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Typography variant="subtitle1">Number of Skids</Typography>
                  <TextField
                    id="skids-number"
                    InputProps={{
                      inputProps: {
                        min: 0
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <FormatListNumbered />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    variant="outlined"
                    type="number"
                    name="numberSkids"
                    value={params.numberSkids}
                    onChange={handleParamsChange}
                  />
                </Grid>
              </>
            }
            {
              (params.mode === 'VLTL') &&
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="subtitle1">Total Linear Feet</Typography>
                <TextField
                  id="skids-number"
                  required
                  InputProps={{
                    inputProps: {
                      min: 0
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <FormatListNumbered />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  variant="outlined"
                  type="number"
                  name="linearTotalFeet"
                  value={params.linearTotalFeet}
                  onChange={handleParamsChange}
                />
              </Grid>
            }

            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle1">Accessorials</Typography>
              <AccesorialsInput initialAccesorials={params.accessorials} handleChange={handleAccesorialsChange} />
            </Grid>
            {
              (params.mode === 'LTL' || params.mode === 'VLTL') &&
              <>
                <Grid item xs={12}>
                  {fields.map((item, index) => (
                    <Grid key={index} container spacing={4}>
                      <Grid item xs={12} sm={6} md={2}>
                        <Typography variant="subtitle1">Item Weight</Typography>
                        <TextField
                          id="item-weight"
                          InputProps={{
                            inputProps: {
                              min: 0
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                <FormatListNumbered />
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          variant="outlined"
                          type="number"
                          name={`freightInformationLTL[${index}].weight`}
                          defaultValue={`${item.weight}`}
                          inputRef={register} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <Typography variant="subtitle1">Length</Typography>
                        <TextField
                          id="length"
                          InputProps={{
                            inputProps: {
                              min: 0
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                <FormatListNumbered />
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          variant="outlined"
                          type="number"
                          name={`freightInformationLTL[${index}].length`}
                          defaultValue={`${item.length}`}
                          inputRef={register} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <Typography variant="subtitle1">Width</Typography>
                        <TextField
                          id="width"
                          InputProps={{
                            inputProps: {
                              min: 0
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                <FormatListNumbered />
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          variant="outlined"
                          type="number"
                          name={`freightInformationLTL[${index}].width`}
                          defaultValue={`${item.width}`}
                          inputRef={register} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <Typography variant="subtitle1">Height</Typography>
                        <TextField
                          id="height"
                          InputProps={{
                            inputProps: {
                              min: 0
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                <FormatListNumbered />
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          variant="outlined"
                          type="number"
                          name={`freightInformationLTL[${index}].height`}
                          defaultValue={`${item.height}`}
                          inputRef={register} />

                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="subtitle1">Freight Class</Typography>
                        <FormControl fullWidth variant="outlined">
                          <Controller
                            control={control}
                            name={`freightInformationLTL[${index}].freightClass`}
                            as={
                              <Select id="trinity-select" startAdornment={<LocalShipping style={{ marginRight: '0.5rem' }} />}>
                                {freightClassList.map((person) => (
                                  <MenuItem key={person.value} value={person.value}>
                                    {person.text}
                                  </MenuItem>
                                ))}
                              </Select>
                            }
                          />
                        </FormControl>
                      </Grid>
                      <button className={classes.deleteItemBtn} type="button" onClick={() => remove(index)}>
                        <DeleteForever />
                      </button>
                    </Grid>
                  ))}
                </Grid>

                <Grid item xs={12} sm={11}>
                  <Button
                    type="button"
                    style={{ float: "right" }}
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.submit}
                    onClick={() => append({ length: "" })}>
                    Add Item
                    </Button>
                </Grid>

              </>
            }
            <Grid item xs={11}>
              <FormControlLabel
                control={<Checkbox value="hazMat" color="primary" />}
                label="Haz Material"
              />
              <span className={classes.triggerPopup} onClick={handleClick}>What can't be shipped? <Info style={{ marginLeft: '0.25rem', fontSize: 16, verticalAlign: 'middle', }} color="secondary" /></span>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Grid container spacing={2} className={classes.boxInfo}>
                  <Grid item xs={12}>
                    <Typography className={classes.typography}>WHAT CAN’T BE SHIPPED</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <ul className={classes.simpleList}>
                      <li>- Boats and mobile home</li>
                      <li>- Commodities of extraordinary or unusual value</li>
                      <li>- Firearms, explosives, ammunition</li>
                      <li>- Garbage, refuse, trash</li>
                      <li>- Glass and Glaszing products</li>
                      <li>- Household goods</li>
                      <li>- Ilegal goods</li>
                      <li>- International shipments(i.e. those having an origin or destination outside the USA)</li>
                    </ul>
                  </Grid>
                  <Grid item xs={6}>
                    <ul className={classes.simpleList}>
                      <li>- Less-than-truckload(‘LTL’) shipments</li>
                      <li>- Livestock</li>
                      <li>- Oversize or overweight loads requirering special highway permits and/or escorts</li>
                      <li>- Shipments brokered to another motor carrier </li>
                      <li>- Tobacco</li>
                      <li>- Intermodal shipments, but for drayage</li>
                      <li>- Multi-stop</li>
                    </ul>
                  </Grid>
                </Grid>
              </Popover>
              <Button
                type="submit"
                style={{ float: "right" }}
                variant="contained"
                color="primary"
                size="large"
                className={classes.submit}>
                View Quotes
            </Button>
            </Grid>
          </Grid>
          <Grid item container sm={12} lg={3} spacing={2}>
            <img className={classes.img} src={ShipmentLogo} alt="shipment logo" />
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default RatingForm

import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Button, Fade, Modal, Typography } from '@material-ui/core'
import {acceptTerms} from "../services/getAccount";
import AccountModal from './AccountModal';
import { navigate } from "@reach/router";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(6, 6, 6, 6),
            width: '914px',
            height: '624px',
            minWidth: '320px',
            overflow: 'overlay'
        },
        wrapperContent: {
            flexDirection: 'column',
            margin: '1rem auto',
            width: '30% !important',
            triggerModal: {
                disaplay: 'none',
            }
        },
        buttonGetStarted: {
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '20%',
            paddingTop: '50px'
        },
        modalButtons: {
            textAlign: 'end',
        }
    })
);

const LandingModal = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openModalAccount, setOpenModalAccount] = React.useState(false);

    const handleAccept = () => {
        acceptTerms().then(() => {
            setOpen(false);
            setOpenModalAccount(true);
            navigate('/rating');
        });
    };

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(true);
    };
    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(false);
    };

    return (
        <div className={classes.wrapperContent}>
            <div className={classes.buttonGetStarted}>
                <Button variant="contained" color="primary" style={{ width: '11rem', height: '3rem' }} onClick={handleOpen}>
                    Get started
                </Button>
            </div>
            <AccountModal isOpen={openModalAccount}  setIsOpen={setOpenModalAccount}/>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                disableBackdropClick>
                <Fade in={open}>
                    <div className={classes.paper} >
                        <Typography variant="h5"><h5>Terms and conditions</h5></Typography>
                        <Typography variant="h6" style={{ fontSize: '16px' }}>
                            <p>
                                PROJECT44 TL SPOT RATING
                                BETA TERMS OF USE
                                PROJECT44 INC. (“P44” OR “WE”) IS WILLING TO PROVIDE ACCESS TO THE BETA VERSION OF
                                ITS PROPRIETARY TRUCK LOAD SPOT RATING APPLICATION (THE “APPLICATION”) TO YOU
                                AS THE INDIVIDUAL, THE COMPANY, OR THE LEGAL ENTITY (REFERENCED BELOW AS “YOU”)
                                ONLY ON THE CONDITION THAT YOU ACCEPT ALL OF THE TERMS OF THESE TERMS OF USE
                                (“AGREEMENT”).   READ THE TERMS AND CONDITIONS OF THIS AGREEMENT CAREFULLY
                                BEFORE (A) SIGNING AN ACKNOWLEDGMENT AGREEING TO THIS AGREEMENT WITH P44, (B)
                                CLICKING ANY ACCEPTANCE OF THIS AGREEMENT OR (C) USING THE APPLICATION.  THIS IS
                                A   LEGAL   AND   ENFORCEABLE   CONTRACT   BETWEEN   YOU   AND   P44.     BY   SIGNING   AN
                                ACKNOWLEDGMENT AGREEING TO THIS AGREEMENT WITH P44, CLICKING ANY ACCEPTANCE
                                OF THIS AGREEMENT OR USING THE APPLICATION, YOU AGREE TO BE BOUND BY THE TERMS
                                AND CONDITIONS OF THIS AGREEMENT.
                                FOR THE SAKE OF CLARITY, IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF
                                (AND FOR USE ON BEHALF OF) A COMPANY OR OTHER ENTITY (A “CORPORATE ENTITY”),
                                YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH CORPORATE ENTITY TO
                                THE   TERMS   OF   THIS   AGREEMENT   AND   YOU   ACKNOWLEDGE   THAT   THE   TERM   “YOU”
                                REFERENCED BELOW REFERS TO SUCH CORPORATE ENTITY.
                                1.
                                Overview
                                .  P44 is in the business of, among other things providing a tracking solution for shippers of goods or
                                third-party   logistics   companies   (collectively,
                                “Shippers”)   through   various   carriers   (“Carriers”).     P44   has   recently
                                created the Application, which is a beta version, and You have agreed to use and evaluate such Application as
                                specified herein. The Application is subject to modification from time to time at P44’s sole discretion, for any purpose
                                deemed appropriate by P44.
                                2.
                                Right to Use Application
                                .  P44 grants you a non-exclusive, non-transferable, license during the Beta Term, to
                                access   and   use   the   Application   for   testing   and   evaluation   purposes.     You   are   responsible   for   maintaining   and
                                safeguarding the security of your account passwords and shall ensure that your Users do not share their passwords
                                with any other person or allow any other person to access the Application under your account.
                                You agree: (a) that
                                only your authorized Users are permitted to use the Application; (b) that you are responsible for your authorized
                                User’s actions or failures to act in connection with activities contemplated under this Agreement and (c) to otherwise
                                take all commercially reasonable steps to protect the Application from unauthorized use and/or access.  For purposes
                                of this Agreement, “Users” shall mean an individual employee of yours.
                                P44 is under no obligation to provide support
                                for the Application.
                                3.
                                Re
                                strictions on Use of Application
                                .
                                Except as expressly permitted in this Agreement, you shall not, and shall
                                not permit others to, (a) use, modify, copy, or otherwise reproduce the Application in whole or in part; (b) reverse
                                engineer,   decompile,   disassemble,   or   otherwise   attempt   to   derive   the   source   code   form   or   structure   of   the
                                Application; (c) sublicense, assign, share, timeshare, sell, rent, lease, grant a security interest in, use for service
                                bureau purposes, or otherwise transfer the Application or your right to use the Application; (d) create any derivative
                                work of the Application, (e) access the Application in order to build a product or service using similar ideas, features,
                                functions or graphics of the Application, or copy any ideas, features, functions or graphics of the Application   or (f)
                                remove any proprietary notices or labels on the Application. All rights in the Application not expressly granted to you
                                are reserved by P44.
                                4.
                                Transactions
                                .  The Application allows prospective Shippers to, among other things, request offers for Carriers
                                for transporting Shippers goods.  The actual contract for the transporting of goods (each a “Transaction”) is directly
                                between such Shippers and Carriers.  P44 is not a party to such Transaction and shall have no liability to you or to
                                the other party to your Transaction. While P44 tries to offer reliable data, it cannot guarantee that the data provided
                                through the Application will always be available, accurate, complete, and up-to-date. You agree that you will not
                                attempt   to   hold   P44   liable   for   inaccuracies   in   Your   Data   or   in   any   data   provided   by   other   third   parties   on   the
                                Application.   You are solely responsible for all of Your Data you upload to the Application; the truth or accuracy of
                                Your Data; and, if applicable, the goods that you ship, or the services you provide to ship any goods, in connection
                                with any Transaction.   You agree to indemnify P44 and hold P44 harmless from and against any and all claims,
                                damages and liabilities resulting from (i) any permitted use of Your Data by P44 hereunder or (ii) in connection with
                                any Transaction to which you are a party.
                                5.
                                Term
                                of
                                Agreement
                                ;
                                Termination
                                .
                                The term of this Agreement shall continue until the end of the beta term of
                                the Application, as determined by P44 in its sole discretion (the “Beta Term”). In addition,
                                either party may terminate
                                the
                                Beta Term
                                and this Agreement and your use of the Application at any time and for any reason upon notice to the
                                other party
                                .   You may cease use of the Application at any time.   Upon termination, P44 will have no obligation to
                                delete, maintain or provide any of Your Data and will have
                                the right, unless legally prohibited, to retain or delete (in its
                                sole discretion) all of Your Data in its systems or otherwise in its possession or under its control. Except as expressly
                                provided herein, termination by either party will be a nonexclusive remedy for breach and will be without prejudice to
                                any other right or remedy of such party.
                                All provisions of this Agreement which by their nature a
                                re intended to survive
                                the termination of this Agreement shall survive such termination.
                                6.
                                Proprietary
                                Rights
                                .  You
                                acknowledge and agree that as between P44 and you, all right, title and interest in and
                                to   the   Application   and   all   derivatives   thereof   (including   any   and   all   patents,   copyrights,   trade   secret   rights,
                                trademarks, trade names and other proprietary rights embodied therein or associated therewith) are and shall remain
                                P44’s or its licensors’, and this Agreement in no way conveys any right or interest in the Application other than a
                                limited license to use it in accordance herewith.  P44 acknowledges and agrees that as between P44 and you, you
                                shall own all right, title and interest in and to Your Data.
                                7.
                                Your Data
                                .
                                (a)
                                P44 agrees to use appropriate safeguards and comply with all applicable data protection laws, to
                                prevent use or disclosure of Your Data other than as provided for by this Agreement.   P44 agrees to implement
                                industry standard physical safeguards, technical safeguards and policy, procedure and documentation requirements
                                that reasonably and appropriately protect the confidentiality, integrity and availability of Your Data.   Any personally
                                identifiable data you provide to P44 will be maintained, collected, stored and used by P44 solely as described in
                                P44’s privacy policy a copy of which shall be found at www.
                                [_________]
                                .
                                (b)
                                By submitting, uploading, providing, transmitting or otherwise making available any data, including
                                photos, video, images data, text, and other types of works, on or through the Application (“Your Data”), you hereby
                                grant to P44 a nonexclusive, royalty-free, worldwide, transferable and sublicensable right and license to use, modify
                                for the purpose of formatting for display, process, transmit, display
                                , disclose
                                and publish Your Data (i) to other third
                                parties on the Application
                                (and in the event that you are a Carrier
                                then P44 may also
                                disclose,
                                display and publish
                                your name a
                                nd Your Data
                                to third parties on
                                any other P44 application)
                                ,
                                and (ii) to provide the features and functions
                                of the Application. You acknowledge and agree that
                                in addition to the
                                foregoing rights of P44 to use Your Data,
                                P44
                                may collect, analyze, disclose and use anonymized statistics and data derived from Your use of the Application for
                                the purpose of providing, operating, analyzing, developing and improving the Application and any other product or
                                services of P44 provided that such data is anonymized and cannot be used to identify you, Your Data or any of your
                                Users.  All such anonymized data shall be owned by P44.  Neither you nor your Users shall use the Application to: (a)
                                send, upload or otherwise transmit any content that is unlawful, threatening, abusive, harassing, tortious, defamatory,
                                vulgar, obscene, libelous, invasive of another’s privacy, hateful, or racially, ethnically or otherwise objectionable; (b)
                                upload or otherwise transmit, display or distribute any content that infringes any trademark, trade secret, copyright or
                                other   proprietary  or  intellectual  property   rights  of   any  person;   (c)  upload  or  otherwise  transmit   any  material  that
                                contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the
                                functionality of any computer software or hardware or telecommunications equipment; (d) interfere with or disrupt the
                                Application; or (e) violate any applicable law or regulation.
                                8.
                                Disclaimer of Warranty; Disclaimer of Liability
                                .
                                THE APPLICATION IS PROVIDED “AS IS” WITHOUT ANY WARRANTY OF ANY KIND WHATSOEVER, EXPRESS
                                OR IMPLIED,  INCLUDING  WITHOUT  LIMITATION,  IMPLIED  WARRANTIES  OF  MERCHANTABILITY,   FITNESS
                                FOR  A   PARTICULAR  PURPOSE,   NON-INFRINGEMENT   OR  TITLE,   WHICH  ARE   HEREBY   DISCLAIMED.   P44
                                DOES   NOT   REPRESENT   THAT   THE   APPLICATION   WILL   BE   AVAILABLE,   ERROR-FREE   OR   THAT   THE
                                APPLICATION WILL MEET YOUR   REQUIREMENTS OR THAT ALL ERRORS IN THE APPLICATION WILL BE
                                CORRECTED   OR   THAT   THE   APPLICATION   WILL   BE   FREE   OF   VIRUSES   OR   OTHER   HARMFUL
                                COMPONENTS.   NOTWITHSTANDING ANYTHING TO THE CONTRARY STATED HEREIN, P44 WILL NOT BE
                                RESPONSIBLE   OR   LIABLE   FOR   THE   DELETION,   CORRECTION,   DESTRUCTION,   DAMAGE,   LOSS,   OR
                                FAILURE   TO   TRANSMIT,   STORE   OR   MAINTAIN   ANY   OF   YOUR   DATA,   OR   ANY   OTHER   DATA   OR
                                INFORMATION.  YOU ARE SOLELY RESPONSIBLE FOR SEPARATELY BACKING UP AND SECURING ALL OF
                                YOUR DATA.
                                P44   WILL   NOT   BE   LIABLE   TO   YOU   FOR   LOSS   OF   PROFITS,   OR   SPECIAL,   INDIRECT,   INCIDENTAL,
                                CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING  LOST PROFITS AND COSTS, IN CONNECTION
                                WITH THE APPLICATION OR THE PERFORMANCE OF ANY OTHER OBLIGATIONS UNDER THIS AGREEMENT,
                                EVEN IF IT IS AWARE OF THE POSSIBILITY OF THE OCCURRENCE OF SUCH DAMAGES.
                                P44’S CUMULATIVE
                                LIABILITY FOR ANY AND ALL CLAIMS OR CAUSES OF ACTION RELATING TO THIS AGREEMENT THAT ARISE
                                IN ANY CALENDAR YEAR SHALL, IN THE AGGREGATE, BE LIMITED TO THE AMOUNTS PAID TO P44 BY YOU
                                (IF ANY) UNDER THIS AGREEMENT DURING SUCH CALENDAR YEAR. YOU ACKNOWLEDGES THAT THESE
                                LIMITATIONS ARE AN ESSENTIAL ELEMENT OF THIS AGREEMENT AND ABSENT SUCH LIMITATIONS P44
                                WOULD NOT ENTER INTO THIS AGREEMENT.
                                9.
                                Confidentiality
                                .  For the purpose of this Agreement, “
                                Confidential Information
                                ” means non-public information
                                disclosed by either party to the other party, either directly or indirectly, in writing, orally or by inspection of tangible
                                objects, or to which the other party may have access, or any other information which a reasonable person would
                                consider   confidential   and/or   which   is   marked   “confidential”   or   “proprietary”   or   some   similar   designation   by   the
                                disclosing   party   or   which   is   of   a   confidential   nature   even   though   not   specifically   so   designated.   Confidential
                                Information will not, however, include any information that (i) was publicly known and made generally available in the
                                public domain prior to the time of disclosure by the disclosing party; (ii) becomes publicly known and made generally
                                available after disclosure by the disclosing party to the receiving party through no action or inaction of the receiving
                                party; (iii) is already in the possession of the receiving party at the time of disclosure by the disclosing party, as
                                shown by the receiving party’s files and records; (iv) is obtained by the receiving party from a third party without a
                                breach  of   the  third  party’s  obligations  of  confidentiality;   or  (v)  is  independently  developed  by  the  receiving  party
                                without  use of or reference to the disclosing party’s Confidential Information, as shown by documents and other
                                competent evidence in the receiving party’s possession.  Except as permitted hereunder, each of the parties agrees:
                                (a) not to disclose any of the other party’s Confidential Information to any third parties except as mandated by law
                                and except to those agents, advisors, or subcontractors who have a reasonable need to access such information,
                                and who agree to be bound by confidentiality obligations no less stringent than those set forth in this Agreement; (b)
                                not to use any of the other party’s Confidential Information for any purposes except carrying out such party’s rights
                                and responsibilities under this Agreement; and (c) to keep the other party’s Confidential Information confidential using
                                the same degree of care such party uses to protect its own confidential information; provided, however, that such
                                party   shall   use  at   least   reasonable   care.   If   a  party   is   required   by   law  to  disclose  the   other   party’s   Confidential
                                Information, it will promptly notify the other party (providing notice prior to disclosure if permitted by law), and provide
                                reasonable assistance in seeking protection of such Confidential Information. Upon termination or expiration of this
                                Agreement the receiving party will promptly return or destroy all of the disclosing party’s Confidential Information in its
                                possession.  This section shall survive termination of this Agreement.
                                10.
                                Feedback
                                . If you choose to provide input and suggestions regarding problems with or proposed modifications or
                                improvements to the Application (“Feedback”), then you hereby grant P44 an unrestricted, perpetual, sublicensable,
                                irrevocable, non-exclusive, fully-paid, royalty-free right to exploit the Feedback in any manner and for any purpose,
                                including to improve the Application and create other products and services.  Upon the reasonable request of P44,
                                you shall provide Application with up to two hours per month of product review of the Application (the “Reviews”)
                                within a reasonable time.  Any Feedback or Reviews shall be Confidential Information of P44.
                                11.
                                Miscellaneou
                                s
                                .  This Agreement contains the entire agreement between the Parties with respect to the subject
                                matter hereof, and supersedes all prior or contemporaneous proposals, understandings, representations, warranties,
                                covenants,   and   any   other   communications   (whether   written   or   oral)   between   the   Parties   relating   thereto   and   is
                                binding   upon   the   Parties   and   their   permitted   successors   and   assigns.     P44   reserves   the   right   to   change   this
                                Agreement   on  a  going-forward  basis.  Please  check  this  Agreement   periodically  for  changes.   If  a  change  to  this
                                Agreement materially modifies your rights or obligations, P44 may require that you accept the modified Agreement in
                                order to continue to use the Application. Material modifications are effective upon your acceptance of the modified
                                Terms. Immaterial modifications are effective upon publication. Except as expressly permitted in this Section 11, the
                                Agreement  may be amended only by a written agreement signed by your and P44’s authorized representatives.
                                Neither this Agreement nor any interest herein may be assigned or otherwise transferred (by operation of law or
                                otherwise) in whole or in part by you.   No waiver of any breach of any provision of this Agreement constitutes a
                                waiver of any prior, concurrent or subsequent breach of the same or any other provisions, and will not be effective
                                unless made in writing and signed by an authorized representative of the waiving party.  If any provision or portion of
                                this Agreement is held to be unenforceable or invalid, the remaining provisions and portions shall nevertheless be
                                given full force and effect, and the parties agree to negotiate, in good faith, a substitute valid provision which most
                                nearly effects the parties' intent in entering this Agreement.   This Agreement shall be governed by and construed
                                under the laws of the State of Illinois, without regard to principles of conflicts of laws.   Any litigation by one party
                                against   the   other   arising   under   these   this   Agreement   or   concerning   any   rights   under   this   Agreement   will   be
                                commenced and maintained in any state or federal court located in Cook County, IL and both parties hereby submit
                                to the jurisdiction and venue of any such court.  Application of the U.N. Convention of Contracts for the International
                                Sale of Goods is expressly excluded.  P44 and you are independent contractors, and nothing in this Agreement shall
                                be construed as making the Parties’ partners or creating the relationships of employer and employee, master and
                                servant, or principal and agent between the Parties, for any purpose whatsoever.
                                Last Updated November
                                22
                                __
                                , 2020

                            </p>
                        </Typography>

                        <div className={classes.modalButtons}>
                            <Button variant="contained" color="primary" style={{ width: '10rem', height: '2.5rem', backgroundColor: 'transparent', color: '#00558B' }} onClick={handleClose}>
                                <h4>DECLINE</h4>
                            </Button>

                            <Button variant="contained" color="primary" style={{ width: '10rem', height: '2.5rem' }} onClick={handleAccept}>
                                <h4>ACCEPT TERMS</h4>
                            </Button>
                        </div>

                    </div>
                </Fade>
            </Modal>
        </div>
    )
};

export default LandingModal;

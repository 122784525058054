import { API_URL } from './settings'

interface Params {
  user: string
  page?: number
  limit?: number
}

interface RecentQuotes {
  page: string
  search: RecentSearch[]
  size: string
  totalPages: string
}

const getRecentSearches = (_key: string, { page = 1, limit = 30 }: Params) =>
  fetch(
    `${API_URL}/api/v1/search/getRecentSearchByUser?page=${page}&limit=${limit}`,
    {
      credentials: 'include'
    }
  ).
  then(res => res.json()).
  then((data: RecentQuotes) => Array.isArray(data?.search) ? data.search : []).
  catch(() => [])

export default getRecentSearches

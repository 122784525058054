import React, { useCallback, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { CircularProgress, TextField } from '@material-ui/core'
import { LocationOn } from '@material-ui/icons'
import debounce from 'just-debounce-it'

import useLocationInput from '../hooks/useLocationInput'

interface Props {
  handleChange: (location: Location) => void
  currentLocation: Location
}

const LocationInput = ({ handleChange, currentLocation }: Props) => {
  const [searchInput, setSearchInput] = useState('')
  const { loading, locations } = useLocationInput(searchInput)

  const debouncedSearch = useCallback(debounce((value: string) => setSearchInput(value), 500), [])

  return (
    <Autocomplete
      getOptionSelected={(locations, value) => locations.zipCode === value.zipCode}
      getOptionLabel={locations => locations.label}
      options={locations}
      onChange={(_evt, selectedLocation) => selectedLocation && handleChange(selectedLocation)}
      value={currentLocation.zipCode ? currentLocation : null}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          variant="outlined"
          onChange={evt => debouncedSearch(evt.target.value)}
          InputProps={{
            ...params.InputProps,
            startAdornment: <LocationOn />,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default LocationInput

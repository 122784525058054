import { API_URL } from './settings'

export default () =>
  fetch(
    `${API_URL}/api/v1/control-provider/getControlProviderByCompany`,
    {
      credentials: 'include'
    }
  ).
  then(res => res.json()).
  then((data: Response) => Array.isArray(data) ? data : []).
  catch(() => []);

import { API_URL } from './settings'

export default (input: string) =>
  fetch(
    `${API_URL}/api/v1/location/getLocationDetailByCriteria?criteria=${input}`,
    {
      credentials: 'include'
    }
  )
  .then(res => res.json())
  .then((data: Response) => Array.isArray(data) ? data : [])
  .catch(() => [])

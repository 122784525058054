import React, { ReactNode, useState } from 'react'

import { currentDate } from '../helpers'

export type Props = {
  children: ReactNode
}

interface ContextParams {
  params: RatingParams
  setParams: React.Dispatch<React.SetStateAction<RatingParams>>
}

const initialParams = {
  totalweight: "",
  equipmentType: "",
  numberSkids: "",
  linearFeet: "",
  linearTotalFeet: "",
  accessorials: [],
  destination: {},
  destinationCountry: "US",
  isLTL: false,
  isVLTL: false,
  miles: null,
  mode: "FTL",
  origin: {},
  originCountry: "US",
  shipDate: currentDate,
  truckLoadType: "ftl",
  freightInformationLTL: [],
  freightInformationVLTL: {
    linearTotalFeet: ""
  },
  code: "",
  name: "",
  _id: "",
};

const RatingParamsContext = React.createContext<ContextParams>({
  params: initialParams,
  setParams: () => { }
});

export const useRatingParams = () => React.useContext(RatingParamsContext);

const RatingParamsContextProvider = ({ children }: Props) => {
  const [params, setParams] = useState<RatingParams>(initialParams);

  return (
    <RatingParamsContext.Provider value={{ params, setParams }}>
      {children}
    </RatingParamsContext.Provider>
  )
};

export default RatingParamsContextProvider

import { API_URL } from './settings'

type User = {
  user?: Object
  ok?: boolean
  auth: string
}

export const getLoginWithTokenUserData = async (): Promise<User>=> {
  try {
    const response = await fetch(
      `${API_URL}/api/v1/account/authToken`,
      {
        method: 'POST',
        credentials: 'include'
      }
    );
    return {
      user: await response.json(),
      ok: response.ok,
      auth: 'auth'
    }
  } catch (error) {
    return {
      auth: 'error'
    }
  }
};

import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


import LandingModal from '../components/LandingModal';
import NavBar from "../components/NavBar";
import step_connect from '../assets/images/step_connect.svg'
import step_quote from '../assets/images/step_quote.svg'
import step_tender from '../assets/images/step_tender.svg'

const useStyles = makeStyles((theme) => ({
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  center: {
    flexDirection: 'column',
    margin: '1rem auto'
  },
  welcomeScreen: {
    backgroundColor: "#FBFCFC",
    minHeight: '100vh'
  },
  welcomeScreenBack: {
    backgroundColor: '#FBFCFC',
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  img: {

  },
}));

const Landing = () => {
  const classes = useStyles();

  return (

    <Grid className={classes.welcomeScreenBack}>
      <header><NavBar></NavBar></header>
      <Container maxWidth="md" className={classes.welcomeScreen}>
        <Grid>
          <Grid item md={12}>
            <Typography component="h1" variant="h3" align="center" gutterBottom style={{ fontSize: "36px", paddingTop: "2rem", marginBottom: "0", color: "black !important" }}>
              Welcome!
            </Typography>

            <Typography align="center" paragraph style={{ fontSize: "20px", margin: "0" }}>
              Thank you for your trusted partnership.
            </Typography>

            <Typography align="center" paragraph style={{ fontSize: "20px", margin: "0" }}>
              North America Customers are cordially invited to participate in our Truckload Spot
              Rating Pilot.
            </Typography>

            <Typography variant="h6" align="center" paragraph style={{ fontSize: "1rem", marginBottom: "0" }}>
              This Pilot will delivers synchronous spot rates from trusted
              capacity providers.
            </Typography>

            <Typography variant="h6" align="center" paragraph style={{ fontSize: "1rem", margin: "0" }}>
              Compare your contract rates with the spot rates and tender
              directly from the app if you choose.
            </Typography>
          </Grid>
        </Grid>

        <Grid>
          <Grid item md={12} style={{ margin: "2rem" }}>
            <Typography component="h3" variant="h3" align="center" gutterBottom style={{ fontSize: "25px", color: "black !important" }}>
              How it works
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={4}>
            <div className={classes.root}>
              <span><img className={classes.img} src={step_connect} alt="step_connect" /></span>
            </div>

            <Typography variant="h6" align="center" paragraph style={{ fontSize: "1rem" }}>
              <h3>Connect</h3>
            </Typography>

            <Typography variant="h6" align="center" paragraph style={{ fontSize: "15px" }}>
              Request access to any of our trusted capacity providers or
              configure your own using their API credentials.
            </Typography>
          </Grid>

          <Grid item md={4}>
            <div className={classes.root}>
              <span><img className={classes.img} src={step_quote} alt="step_quote" /></span>
            </div>

            <Typography variant="h6" align="center" paragraph style={{ fontSize: "1rem" }}>
              <h3>Get Quotes</h3>
            </Typography>

            <Typography variant="h6" align="center" paragraph style={{ fontSize: "15px" }}>
              Provide details about your shipment and instantly get rate quotes
              from all your connected capacity providers.
            </Typography>
          </Grid>

          <Grid item md={4}>
            <div className={classes.root}>
              <span><img className={classes.img} src={step_tender} alt="step_tender" /></span>
            </div>
            <Typography variant="h6" align="center" paragraph style={{ fontSize: "1rem" }}><h3>Tender</h3></Typography>
            <Typography
              variant="h6" align="center" paragraph style={{ fontSize: "15px" }}>
              Tender the shipment to your chosen capacity provider and let them
              and the platform handle the rest!
            </Typography>
          </Grid>

          <Grid container>
            <Grid item md={12}>
              <LandingModal />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Landing;
